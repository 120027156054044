.wrapper {
  $offset: 2px;
  margin: -8px 0;
  position: relative;
  top: $offset;

  :global(.ant-tabs-nav) {
    margin-bottom: 0;
  }

  :global(.ant-tabs-tab) {
    position: relative;
    top: -$offset;
    display: flex;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
  }
  :global(.ant-tabs-tab+.ant-tabs-tab) {
    margin: 0;
  }
}
