@import "main.module";


@mixin rangeTrack {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: block;
  height: 3px;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.row {
  display: flex;
  align-items:center;
  padding: 10px 0px;
  gap: 1em;
}

.day {
  flex-grow: 1;
  max-width: 40px;
  font-size: 14px;
  font-weight: 600;
}

.checkbox {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  max-width: 40px;
  i {
    border-color: themeColor(action);
  }
}

.timeRange {
  flex-grow: 1;
  padding: 0 30px;
}


.timeRangeSliderContainer {
  transition: left 0.3s ease-out;
  height: 100%;
}
