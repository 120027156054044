@import "main.module";
@import "../../../vars.module";

.wrapper {
  position: relative;
  display: flex;
  width: fit-content;
}

.main {
  align-self: flex-end;
  position: relative;
}

.symbol {
  position: relative;
  //top: -$iconSize/2;
  left: -$iconSize/2;

  width: $iconSize;
  height: $iconSize;
  border-radius: $iconSize;
  border: 2px solid white;
  box-sizing: border-box;
}

.orange {
  color: themeColor(orange);
}
.name {
  position: absolute;
  transform: translate(-50%, -50%);
  width: max-content;
}
