@mixin custom-scrollbar{
  &::-webkit-scrollbar{
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #F0F3F6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
      background: #99ACC2;
      border-radius: 10px;
  }
    &::-webkit-scrollbar-thumb:hover {
      background: #8495a8;
  }
}
