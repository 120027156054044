.gs-tooltip {
  position: relative;
}

.gs-tooltip::after {
  background-color: #333;
  border-radius: 10px;
  color: white;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
}
.gs-tooltip:hover::after {
  display: block;
}

// bottom position is default
.gs-tooltip:not([data-tip-place])::after,
.gs-tooltip[data-tip-place="bottom"]::after{
  content: attr(data-tip);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}
.gs-tooltip[data-tip-place="top"]::after {
  content: attr(data-tip);
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.gs-tooltip[data-tip-place="right"]::after {
  content: attr(data-tip);
  top: -50%;
  right: 0;
  transform: translateX(calc(100% + 10px));
}

.gs-tooltip[data-tip-place="left"]::after {
  content: attr(data-tip);
  top: -50%;
  left: 0;
  transform: translateX(calc(-100% - 10px));
}


// Arrow
.gs-tooltip::before {
  background-color: #333;
  content: ' ';
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 999;
}

.gs-tooltip:hover::before {
  display: block;
}

// bottom is default
.gs-tooltip:not([data-tip-place])::before,
.gs-tooltip[data-tip-place="bottom"]::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.gs-tooltip[data-tip-place="top"]::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.gs-tooltip[data-tip-place="right"]::before {
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.gs-tooltip[data-tip-place="left"]::before {
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}
