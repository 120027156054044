@import "main.module";

.aside {
  border-inline-end: 1px var(--line-type) var(--color-split);

  > :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
  }

  :global(.ant-menu-root) {
    border-inline-end: none !important;
  }
}

.navigation {
  flex: 1 1 0;
  overflow: auto;
}
