.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:  25px;
  gap: 40px;
}
.title {
  font-size: 16px;
  font-weight: 600;
}
.closeOuter {
  $size: 30px;
  width: $size;
  height: $size;
  border-radius: $size/2;
  background-color: #EAF0F6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.closeInner {

}
