@import "main.module";

:global{
  .ReactModalPortal{
    z-index: 1000;
    position: fixed;
    width: 1px;
    height: 1px;
  }
  .gs-modal {
    outline: none;
    max-width: 90%;
    min-width: 290px;
    box-shadow: 2px 4px 12px 4px #91a8b0;
    border-radius: var(--border-radius);
    transition: $gs-transition;
    cursor: default;
    opacity: 0;
    background-color: white;
    position: relative;
    &._open{
      opacity: 1;
      margin: 20px;
    }
    ._modal-end {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
    //===============================================================
    // Elements
    //===============================================================
    &__overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      background-color: rgba(124, 152, 182, 0.5);
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      overflow: auto;
      transition: $gs-transition-long;
      transition-delay: 0.15s;
      &._open{
        opacity: 1;
      }
      &::before,
      &::after {
        content: ' ';
        display: block;
        flex: 1 1 auto;
      }
    }

    &__hide {
      position: absolute;
      z-index: 1;
      top: -35px;
      right: -35px;
      cursor: pointer;
      margin: 0;
      svg{
        &,
        path{
          transition: $gs-transition-fast;
          fill: #eff5f7;
        }
      }
      &:hover{
        svg{
          &,
          path{
            fill: currentColor;
          }
        }
      }

      &._outside {
        top: -25px;
        right: -25px;
      }
    }

    &__header {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      background: {
        // color: palletteColor(d-blue);
        color: white;
      };
      padding: $pd-sm $pd-xl 0;
      margin: 0 $pd-md;
      font: Text(light, xxLarge);
      text-align: center;
      color: themeColor(text);
      &._danger {
        background-color: palletteColor(red);
        display: flex;
        margin: 0;
        align-items: center;
      }

      &._inverted {
        background-color: white;
        color: themeColor(text);
        padding-bottom: 0;
      }
    }

    &__content {
      padding: $pd-lg $pd-md $pd-md;
      background: {
        color: white;
      };
      max-height: 80vh;
      position: relative;
      overflow: auto;
      .spinner-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        z-index: 5;
        top: 0px;
        left: 0px;
      }
      .content_row{
        padding: $pd-md 0;
        &._flex{
          display: flex;
        }
        &._center{
          justify-content: center;
          align-items: center;
        }
        &._rows{
          flex-direction: column;
        }
        .gs-select{
          &._select_mailbox{
            max-width: 390px;
          }
        }
      }
      .labels{
        display: flex;
        flex-wrap: wrap;
        max-height: 25vh;
        overflow: auto;
        border: 1px solid themeColor(border);
        border-radius: getRadius(medium);
        padding: $pd-xs $pd-sm;
        & + .gs-select{
          margin-top: $pd-md;
          .Select{
            height: 28px;
            line-height: 28px;
            &-multi-value-wrapper{
              align-items: center;
              & + span{
                color: transparent;
              }
            }
            &-value{
              text-align: center;
              justify-content: space-between;
              background: transparent;
              color: #333;
              display: flex;
              flex-direction: row-reverse;
              height: 28px;
              line-height: 28px;
              border-radius: 90px;
              border: 1px solid #0070d2;
              padding: 0px $pd-sm;
              margin-right: $pd-sm;
              & + .Select-value{
                margin-left: $pd-sm;
              }
              &-icon,
              &-label{
                line-height: 28px;
                padding: 0 5px;
              }
              &-icon{
                border: none;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
              }
            }
          }
        }
      }
      &._oy{
        overflow-y: auto;
      }
      &._ox{
        overflow-x: auto;
      }
      &._nopad {
        padding: 0;
      }

      &._no-top-pd {
        padding-top: 0;
      }
      &._no-bot-pd {
        padding-bottom: 0;
      }
      &._md-bot-pd {
        padding-bottom: $pd-md;
      }
      &._no-vt-pd {
        padding-top: 0;
        padding-bottom: 0;
      }

      &._nofill {
        background-color: transparent;
      }
      &._long{
        height: 60vh;
      }
      &._export_modal{
        flex-direction: column;
        display: flex;
      }
      &._standart {
        width: 530px;
        max-width: 530px;
      }

      &._medium {
        width: 630px;
        max-width: 630px;
      }

      &._wide {
        width: 960px;
      }
    }
    &__actions {
      padding: 0;
      margin-top: $mg-xl;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      background: {
        color: white;
      };
      .gs-btn{
        & + .gs-btn{
          margin-left: 8px;
        }
      }
      &._between{
        justify-content: space-between;
      }
      &--select{
        display: flex;
        flex-direction: column;
        width: 100%;
        label{
          padding: 0 $pd-xl 0 $pd-md;
        }
      }
    }
    &__footer{
      padding-bottom: 0;
      gap: 5px;
      &--start{
        display: flex;
        justify-content: flex-start;
        flex:1;
      }
      &--end{
        display: flex;
        justify-content: flex-end;
        flex:1;
      }
      &._danger{
        color: palletteColor(red);
      }
    }
  }

  .gs-modal-animating {
    transform: translate3d(0,0,0);
  }
}



@media (max-width: 800px) {
  :global{
    .gs-modal {
      max-width: 95%;

      &._open{
        margin: 10px;
      }
    }
  }
}
