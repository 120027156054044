@import "main.module";

:global{
  body {
    --color-outbox: #E8EEFF;
    --color-outbox-border: #C4D2FF;

    --color-note: #EE8E36;
    --color-note-bg: #FFF2E2;
    --color-note-bg-icon: #FEF9F3;
    --color-note-border: #F9D6B6;

    --color-segment-border: #e6e9ee; // --segmented-track-bg overwrite
    &[data-theme="dark"] {
      --color-segment-border: var(--color-border);
    }
    interpolate-size: allow-keywords;
  }

  [data-theme="dark"] .ant-table-wrapper .ant-table-thead >tr>td {
    background-color: #1d1d1d;
  }
  // todo: move to segmented component
  .ant-segmented {
    background-color: var(--color-segment-border);
    padding: 4px;
  }
  .ant-segmented-item {
    min-width: 120px;
  }
  .ant-segmented .ant-segmented-group {
    gap: 4px;
  }
  // TABLE GLOBAL STYLES
  .ant-table .ant-table-thead > tr > th,
  .ant-table .ant-table-thead > tr > td {
    background-color: var(--color-bg-base);
  }
  .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
  .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 0;
    padding: var(--padding);
    background-color: var(--color-bg-base);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  @import "global/index.module";
  .MyDragHandleClassName{
    background: blue;
  }
  .react-grid-item {
    background: #00A4BD ;
    background-color: #00A4BD;
    .react-grid-placeholder {
      background: #00A4BD !important;
      background-color: #00A4BD !important;
      border-radius: 5px !important;
    }
  }
  .react-grid-placeholder {
    background: #00A4BD !important;
    background-color: #00A4BD !important;
    border-radius: 5px !important;
  }

  .layout-splitter + .layout-pane {
    overflow: visible !important;
  }
  .splitter-layout .layout-pane.layout-pane {
    overflow: unset;
  }

  .rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
      color: themeColor(light-gray);
  }

  .gs-column-plugin #launcher {
    display: none;
  }
  .ant-picker-ranges{
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow{
    bottom: 1px;
  }
  .ant-tooltip-placement-left .ant-tooltip-arrow{
    right: 1px;
  }
  .ant-tooltip-inner{
    border-radius: 5px;
  }
}
