html {
  box-sizing: border-box;
}
[role="button"] {
  cursor: pointer;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: inherit;
  &::before{
    box-sizing: inherit;
  }
  &::after{
    box-sizing: inherit;
  }
}

input[type="search"],
input[type="search"]:focus  {
  -webkit-appearance: none;
  outline: -webkit-focus-ring-color auto 0px;
}
a,
a:hover,
a:active,
a:active:hover,
a:focus, a:visited {
  text-decoration: none;
}
input,
select, textarea {
  font: inherit;
  box-sizing: inherit;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  font: 400 14px/1.4 Onest, 'Lucida Grande', arial;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

strong {
  font-weight: 600;
}
svg {
  font: inherit;
}
