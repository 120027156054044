:global(.ant-message .ant-message-notice-wrapper) {
  text-align: right;
}
.content :global(.ant-message-notice-content) {
  max-width: 350px;
  text-align: left;
}

.content :global(.ant-message-custom-content) {
  display: flex;
  gap: 8px;
  word-break: break-word;
}
