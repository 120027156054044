@import "main.module";

.LoadingPage{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: $pd-lg;
  &Action {
    font-size: fontSize(xxLarge);
    margin: $mg-sm $mg-xl;
  }
  :global{
    .gs-text{
      text-shadow: 0px 0px 8px themeColor(light-gray);
    }
  }
}

.Message{
  font-size: fontSize(xxLarge);
  color: themeColor(steel);
  margin: $mg-sm $mg-xl;
  cursor: default;
}

.Label{
  font-size: fontSize(xxLarge) * 3; // 100px
  font-weight: 900;
  margin-bottom: $mg-xl;
  cursor: default;
  color: themeColor(flow-name);
  text-shadow: 4px 4px 4px themeColor(flow-name);
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-name: textShadow;
}

@keyframes textShadow {
  0% {
    text-shadow: -6px 4px 4px themeColor(flow-name);
  }
  50% {
    text-shadow: 8px 4px 4px themeColor(info);
  }
  100% {
    text-shadow: -6px 4px 4px themeColor(flow-name);
  }
}
