.spinner{
  &Container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    animation: show 0.5s ease-in-out;
    background: rgba(255, 255, 255, 0.4);
  }
  &Icon{
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      animation: rotate 2s infinite;
      circle{
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        //stroke: currentColor !important;
        animation: spinner-dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
        fill: transparent !important;
      }
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
