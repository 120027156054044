@import "main.module";

.Preload{
  $preloadClass: &;
  position: absolute;
  // width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparentize(themeColor(background), 0.4);
  &LoginForm {
    width: 100%;
    #{$preloadClass}Text {
      width: unset;
    }
  }
}
