@import "main.module";


@mixin rangeTrack {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: block;
  height: 3px;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}


.timeRange {
  flex-grow: 1;
  padding: 0 30px;
  @media (max-width: 1439px) {
    padding: 0;
    width: 100%;
  }
}

.timeRangeLeftLabel {
  position: absolute;
  top: -27px;
  left: 2px;
  transform: translateX(-50%);
  z-index: 0;
  span {
    color: #CBD6E2;
    font-weight: normal;

  }
}

.timeRangeRightLabel {
  position: absolute;
  top: -27px;
  right: -2px;
  transform: translateX(50%);
  z-index: 0;
  span {
    color: #CBD6E2;
    font-weight: normal;
  }
}

.timeRangeInputRange,
.timeRangeInputRangeDisabled {
  height: 1rem;
  position: relative;
  width: 100%;
}

.timeRangeInputRangeDisabled {
  .timeRangeSlider,
  .timeRangeActiveTrack,
  .timeRangeValueLabel {
    display: none;
  }
  .timeRangeTrack  {
    cursor: default;
  }
}

.timeRangeOneSlider {
  .timeRangeTrack {
    &::before {
      background: themeColor(action);
    }
  }
}

.timeRangeTrack {
  background: palletteColor(active-elem);
  z-index: 1;
  @include rangeTrack;
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: palletteColor(active-elem);
  }
  &::after {
    right: 0;
    transform: translate(50%, -50%);
  }
  &::before {
    left: 0;
    transform: translate(-50%, -50%);
  }
}

.timeRangeActiveTrack {
  background: themeColor(action);
  @include rangeTrack;
}

.timeRangeSliderContainer {
  transition: left 0.3s ease-out;
  height: 100%;
}
.timeRangeLabelContainer {
  position: relative;
  font-size: 12px;
  color: themeColor(action);
  font-weight: 600;
}
.timeRangeSlider {
  appearance: none;
  background: themeColor(action);
  border: 1px solid themeColor(action);
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 15px;
  margin-left: -0.5rem;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 15px;
  z-index: 1;
}

.timeRangeValueLabel {
  position: absolute;
  top: -30px;
  color: black;
  //transform: translateZ(0);
  transform: translateX(-50%);
  left: 2px;
  white-space: nowrap;
}
