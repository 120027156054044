@import "main.module";

.gsFormRow{
  width: 100%;
  position: relative;
  label, .label{
    span {
      font-size: 16px;
      margin-right: 10px;
      line-height: 1.5;
      font-weight: 400;
    }
    p {
      line-height: 1.5;
      color: themeColor(text-dim);
    }
  }
}

.labelRow {
   margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1;
  display: flex;
  // justify-content: space-between;
}

.required:after {
  content: "*";
  color: themeColor(orange);
}

.labelDescription{
  color: #516F90;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  svg{
    path{
      fill:#516F90;
    }
  }
}