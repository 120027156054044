@import "buttons/index.module";
@import "links/index.module";
@import "text/index.module";
@import "tooltip/index.module";
@import "popover/index.module";
@import "notifications/index.module";
@import "timePicker/index.module";

.test{
  .drawer-content-wrapper{
    background-color: rgba(0,0,0,.0);
    box-shadow: none;
  }

}
// .drawer-content-wrapper{
//   background-color: rgba(0,0,0,.0);
// }

// .Popover-below{
//   &::before{
//     display: none;
//   }
// }
// .Popover-above{
//   &::before{
//     display: none;
//   }
// }
// .Popover-tip{
//     display: none;
// }
// .Popover-body{
//   &::before{
//     content: none;
//   }
//   &::after{
//     content: none;
//   }
// }
