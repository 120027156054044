@import "main.module";

.Trigger{
  outline: none;
  border: none;
  background: inherit;
  cursor: pointer;
}
.Icon{
  width: 20px;
  height: 20px;
  svg{
    width: 18px;
    height: 18px;
    fill: #425B76
  }
  &Animated{
    animation: scaleAnimation 1s ease infinite;
    -webkit-animation: scaleAnimation 1s ease infinite;
  }
}
.ContentWrapper{
  width: 100%;
}
.Drawer{
  &Body{
    padding: 0 15px 15px 15px !important;
    @include custom-scrollbar
  }
  &Header{
    border-bottom: none !important;
    padding: 16px 10px !important;
    div{
      color:#425B76 !important;
    }
    }
}
.Note{
  color: #425B76;
  background: #CCEDF2;
  border-radius: 7px;
  display: flex;
  gap: 5px;
  padding: 10px 7px;
  align-items: baseline;
  svg{
    path{
      fill: #425B76;
    }

  }
}
.List{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}
.NoData{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 50%;
  color: #425B76;
  &Title{
    font-size: 16px;
    font-weight: 600;
  }
  &Icon{
    width: 35px;
    height: 35px;
    svg{
      width: 30px;
      height: 30px;
      fill: #425B76
    }

  }
}

@keyframes scaleAnimation {
  0% {
      transform: scale(1.0);
  }
  50% {
    transform: scale(1.2);
}
  100% {
    transform: scale(1.0);
  }
}
