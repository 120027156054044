@import "main.module";

.InputIcon{
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.inputWrapper {
  width: 100%;
  position: relative;
  border: none;
  transition: $gs-transition;
  color: #253342;

  &:hover {
    .Input {
      border-color: #00A4BD;
    }
  }

  &.ShortInput {
    min-width: unset;
    max-width: 425px;
  }

  &Raduis {
    background: white;
    border: 1px solid #99ACC2;
    box-sizing: border-box;
    border-radius: getRadius(xlarge);
  }

  &.Success {
    .validateIcon {
      polyline,
      path {
        stroke: themeColor(success);
      }

      path {
        stroke-dasharray: 46;
        stroke-dashoffset: 177;
        transition: stroke-dasharray .6s ease 0s, stroke-dashoffset .8s ease .3s, stroke .3s ease .6s;
      }

      polyline {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset .5s ease .6s, stroke .3s ease .6s;
      }
    }
  }

  &.Error {
    input {
      border-color: #FF0000;
    }

    .validateIcon {
      polyline,
      path {
        stroke: lighten(themeColor(danger), 15%);
      }

      path {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        transition: stroke-dasharray .6s ease 0s, stroke-dashoffset .8s ease .3s, stroke .3s ease .6s;
      }

      polyline {
        stroke-dasharray: 18;
        stroke-dashoffset: 18;
        transition: stroke-dashoffset .5s ease .6s, stroke .3s ease .6s;
      }

      g {
        path {
          fill: themeColor(danger);
          transition: fill .5s ease .6s;
        }
      }
    }
  }
}


.validateIcon {
  position: absolute;
  z-index: 1;
  right: 14px;
  top: 50%;
  width: 20px;
  height: 20px;
  fill: none;
  margin: -10px 0 0 0;

  polyline,
  path {
    stroke: themeColor(border);
    stroke-width: 1.4;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke .3s ease 0s;
  }

  path {
    stroke-dasharray: 64;
    stroke-dashoffset: 127;
    transition: stroke-dasharray .8s ease .8s, stroke-dashoffset .8s ease .5s;
  }

  polyline {
    stroke-dasharray: 18;
    stroke-dashoffset: 18;
    transition: stroke-dashoffset .5s ease 0s;
  }

  g {
    path {
      fill: transparent;
      transition: fill .5s ease .6s;
    }
  }
}

.Radio {
  &Label {
    cursor: pointer;
    //font: Text();
    //color: themeColor(text);
    display: flex;
    align-items: center;
  }

  &Input {
    display: none;

    &:checked + .radBtn:after {
      width: 10px;
      height: 10px;
    }

    & + .radBtn {
      transition: $gs-transition-long;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      margin-left: $mg-sm;

      &:after,
      &:before {
        position: absolute;
        border-radius: 50%;
        content: '';
        display: flex;
        transition: $gs-transition-long;
      }

      &:after {
        background: themeColor(main);
        width: 0px;
        height: 0px;
      }

      &:before {
        width: 18px;
        height: 18px;
        border: 1px solid themeColor(main);
      }
    }
  }
}

.Textarea {
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 100%;

  &::placeholder {
    color: #99ACC2;
    cursor: pointer;
  }
  &::-webkit-scrollbar{
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #F0F3F6;;
    border-radius: 10px;
    margin: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background:#99ACC2;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background:#8495a8;
  }
}

.Input::placeholder {
  color: themeColor(gray);
}

.Input {
  height: 31px;
  padding: 0 21px 0 7px;
  border-radius: 6px;
  border: 1px solid themeColor(border-radius-color);
  box-sizing: border-box;
  width: 100%;
  -moz-appearance: textfield;
  transition: all 100ms;

  &:focus {
    border-color: #00A4BD;
  }

  &Raduis {
    border-radius: getRadius(xlarge);
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.Arrow {

  &Top {
    top: 5px;
  }

  &Bottom {
    bottom: 4px;
  }
  &Disabled{
    opacity: 0.5;
    pointer-events: none;
  }
  &Top, &Bottom {
    user-select: none;
    position: absolute;
    z-index: 10;
    right: 6px;
    cursor: pointer;
    svg{
      display: block;
    }
    path{
      transition: all 100ms;
    }
    &:hover{
      path{
        stroke: #00A4BD;
      }
    }
  }
}

.DatetimeInput {
  height: 38px;
  padding: 0;
  border: 1px solid themeColor(border);
  border-radius: getRadius(small);
  width: 100%;

  &Wrapper {
    position: relative;
    //&:after{
    //  content: attr(data-label);
    //  position: absolute;
    //  color: #a0a0a0;
    //  top: -10px;
    //  border-radius: getRadius(small);
    //  background: white;
    //  padding: $pd-xxs $pd-sm;
    //  left: 5px;
    //}
  }

  input {
    height: 38px;
    width: 100%;
    padding: $pd-sm $pd-md;
    border: 1px solid transparent;
    background: transparent;
  }
}

.Select {
  min-height: 31px;
  padding: 0;
  width: 100%;
  font-size: 14px;

  :global .Select-control {
    height: 100%;

  }
}

$height: 20px;
$smallHeight: 17px;
.Same{
  & + label {
    background: themeColor(success) !important;
  }
}
.toggleInput {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &Btn {
    opacity: 0;
    display: none;

    &:checked + label {
      opacity: 1;
      background: #00BDA5;
      border: 1px solid #00BDA5;

      &:after {
        left: 19px;
        box-shadow: 0 0px 4px rgba(0, 0, 0, .4);
      }
    }

    & + label {
      width: 37px;
      height: 18px;
      background: #E3E8EE;
      border: 1px solid #00BDA5;
      border-radius: $height;
      display: inline-block;
      position: relative;
      cursor: pointer;
      margin-right: 10px;
      transition: all 0.5s;
      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: #fff;
        left: 0px;
        top: 0px;
        border-radius: $height;
        position: absolute;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        transition: all 0.5s;
      }

      & + label {
        margin-left: $mg-sm;
      }
    }

    &.Big {
      &:checked + label {
        &:after {
          left: 145px;
        }

        &:before {
          color: white;
        }
      }

      & + label {
        width: 165px;
        height: 24px;
        border-radius: 24px;

        &:after {
          width: 24px;
          height: 24px;
          border-radius: 25px;
        }

        &:before {
          content: 'Prospect by visitor location';
          font-size: 10px;
          color: themeColor(text-dim);
          transition: 1s;
          margin: 0 $mg-sm;
        }
      }
    }

    &.Small {
      &:checked + label {
        &:after {
          left: 18px;
        }
      }

      & + label {
        width: 34px;
        height: 17px;
        border-radius: 17px;

        &:after {
          width: 18px;
          height: 17px;
          border-radius: 18px;
        }
      }
    }

    &.Green {
      &:checked + label {
        background: themeColor(success);
      }
    }

    &.Blue {
      &:checked + label {
        background: themeColor(second);
      }
    }

    &.Red {
      &:checked + label {
        background: themeColor(danger);
      }
    }

    &._disabled {
      opacity: 0.4;
      cursor: not-allowed;

      > * {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}

.checkbox {
  $size: 25px;
  $size-lg: 26px;
  $radius: 4px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  //===============================================================
  // Elements
  //===============================================================
  &Input {
    display: none !important;
  }

  &Label {
    font: Text(light, medium);
    color: darken(themeColor(text), 10%);
  }

  &Button {
    content: '';
    position: relative;
    flex: 0 0 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    transition: $gs-transition-long;
    border-radius: 4px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: {
      width: 1px;
      style: solid;
      color: #99ACC2;
    }
    svg{
      opacity: 0;
      transition: $gs-transition;
    }

    &Triple {
      // background: themeColor(border-radius-color);
      &::before {
        width: 100%;
        height: 100%;
        background: white;
        width: 11px;
        height: 5px;
        top: 50%;
        position: absolute;
        margin-top: -4px;
        transform-origin: 50% 50%;
      }

      &Any {
        &::before {
          //
          content: "";
          border-left: 0 solid transparent;
          border-bottom: 1px solid themeColor(main);
          transform: rotate(0);
        }

      }

      &All {
        &::before {
          content: none !important;
          border-left: 1px solid themeColor(main);
          border-bottom: 1px solid themeColor(main);
          transform: rotate(-45deg);
        }
        svg{
          opacity: 1;
        }
      }
    }

    &Big {
      $size: $size-lg;
      flex: 0 0 $size;
      min-width: $size;
      height: $size;
      width: $size;
      border: {
        width: 1px;
        style: solid;
        color: themeColor(border-radius-color);
      }

      &::before {
        width: 11px;
        height: 5px;
        border-width: 1px;
      }
    }
  }

  //===============================================================
  // Checked
  //===============================================================
  &Input:checked + &Button {
    //background: themeColor(main);
    &::before {
      // background: white;
      // width: 5px;
      // height: 5px;
      // border-radius: $radius;
      // left: 4px;
      // top: 4px;
      border-left: 1px solid themeColor(main);
      border-bottom: 1px solid themeColor(main);
      width: 11px;
      height: 5px;
      left: 6px;
      top: 50%;
      position: absolute;
      margin-top: -4px;
      transform-origin: 50% 50%;
      transform: rotate(-45deg);
    }
    svg{
      opacity: 1;
    }
  }

  //===============================================================
  // Disabled
  //===============================================================
  [disabled] + &Button {
    border-color: darken(themeColor(default), 2.75%);
    cursor: not-allowed;
    background: {
      color: lighten(themeColor(default), 7%);
    }

    &::before {
      filter: grayscale(100%);
    }

    [disabled] + &Button + &Label {
      color: lighten(themeColor(text), 20%);
      cursor: not-allowed;
    }
  }
}

$switchSize: 22px;
.AsyncSwitch {
  margin: 0;
  cursor: pointer;

  & > span {
    line-height: $switchSize;
    margin: 0 0 0 4px;
    vertical-align: top;
  }

  input {
    display: none;

    & + div {
      width: $switchSize * 2;
      height: $switchSize;
      //border: 1px solid themeColor(border);
      border: 1px solid lighten(themeColor(main), 15%);
      background: themeColor(border);
      border-radius: $switchSize / 2;
      vertical-align: top;
      position: relative;
      display: inline-block;
      user-select: none;
      transition: $gs-transition-long;

      &:before {
        content: '';
        float: left;
        width: $switchSize - 2;
        height: $switchSize - 2;
        background: white;
        pointer-events: none;
        margin-top: 0;
        margin-left: 0;
        border-radius: inherit;
        transition: $gs-transition-long;
      }

      &:after {
        content: '';
        left: -1px;
        top: -1px;
        width: $switchSize;
        height: $switchSize;
        border: 3px solid transparent;
        border-top-color: themeColor(main);
        border-radius: 50%;
        position: absolute;
        opacity: 0;
      }
    }

    &:checked + div {
      background: themeColor(main);
      border: 1px solid themeColor(main);

      &:before {
        transform: translate($switchSize, 0);
      }

      &:after {
        border-top-color: white;
      }
    }
  }

  &Load {
    input {
      & + div {
        width: $switchSize;
        margin: 0 $switchSize / 2;
        border-color: themeColor(border);

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
          animation: rotate .9s infinite linear;
          animation-delay: .2s;
        }
      }
    }
  }

  &:hover {
    input {
      &:disabled + div {
        border-color: themeColor(border);
      }

      &:checked + div {
        background: themeColor(main);
      }
    }
  }
}

@keyframes rotate {
  0%,
  15% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(290deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.NoLabel{
  margin-right: 0 !important;
}
.TeamBadge{
  min-width: 9px;
  height: 9px;
  border-radius: 50%;
}
.SelectLabel{
  overflow: hidden;
  text-overflow: ellipsis;
}
