@import "main.module";

.Tooltip {
  background: white;
  border: 1px solid themeColor(border-radius-color);
  box-sizing: border-box;
  border-radius: getRadius(xlarge);
  box-shadow: 0 0px 4px rgba(0,0,0,.4);
}


// Dark theme
// TODO: refactor/clean up (this code just copied from src/components/routes/crmGroup/Details/Extended/Extended.scss)
@mixin lineText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity {
  &Wrap {
    padding: 0 $pd-sm;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &Row {
    margin: $mg-md 0;

    & + & {
      margin: $mg-lg 0;
    }

    &Line {
      display: block;
      height: 1px;
      width: 30px;
      background: themeColor(active-elem);
      margin: 0 $mg-sm;
      margin-top: $mg-sm;
      border-radius: getRadius(xsmall);
    }
    &Data {
      width: fit-content;
      justify-content: center;
      width: fit-content;
      margin: auto;
      background: themeColor(active-elem);
      min-height: 30px;
      align-items: center;
      display: flex;
      padding: 0 $pd-md;
      border-radius: 15px;
      max-width: 460px;

      &Top {
        display: flex;
        justify-content: center;
        align-items: center;
        color:  themeColor(primary);

        &Time{
          margin-left: $mg-lg;
          align-items: center;
          display: flex;
          color: themeColor(text-dim);
        }
        .chainIcon {
          margin-right: $pd-sm;
        }
        .infoIcon {
          margin-left: $pd-sm;
        }
      }
      &Main {
        background: transparentize(themeColor(active-elem), 0.5);
        border-radius: getRadius(xxlarge);
        padding: $pd-sm $pd-md;
        margin: $mg-xxs 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .link {
          flex: 1 1 100px;
        }
        .duration {
          @include lineText;

          $width: 60px;
          flex: 0 0 $width;
          min-width: $width;
          margin-left: $pd-sm;
        }
        .time {
          @include lineText;

          $width: 70px;
          flex: 0 0 $width;
          min-width: $width;
        }
      }
    }
  }
  &AndMessagesBlock {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  &Tooltip {
    padding: 12px 10px !important;
    background-color: #222B45 !important;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    top: -9px !important;
    line-height: 1;
    box-shadow: none !important;
    border: none;
    &[data-placement="right"] {
      top: 0px !important;
    }
    &White{
      color: #425B76;
      z-index: 1001 !important;
      border: none;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.09) !important;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.09)) drop-shadow(2px -2px 4px rgba(0, 0, 0, 0.07));
      top: 6px !important;
    }

    &Arrow {
      color: red;

      &::before, &::after {
        width: 10px;
        height: 10px;
        content: "";
        top: -1px !important;
        left: 50%;
        transform: translateX(-50%);
        border-width: 10px !important;
      }

      &[data-placement="bottom"] {
        &::before {
          content: none;
        }

        &::after {
          border-color: transparent transparent #222B45 transparent !important;
          border-bottom: 10px solid #222B45 !important;
          border-top: none;
        }
      }

      &[data-placement="top"] {
        &::before {
          border-color: transparent transparent #222B45 transparent !important;
          border-top: 10px solid #222B45 !important;
          border-bottom: none;
        }

        &::after {
          content: none;
        }
      }

      &[data-placement="right"] {

        &::before {
          content: none;
        }

        &::after {
          border-color: transparent transparent #222B45 transparent !important;
          border-top: 10px solid #222B45 !important;
          border-bottom: none;
          transform: rotate(90deg);
          margin-left: -10px;
        }
      }
      &[data-placement="left"] {

        &::before {
          content: none;
        }

        &::after {
          border-color: transparent transparent #222B45 transparent !important;
          border-top: 10px solid #222B45 !important;
          border-bottom: none;
          transform: rotate(270deg);
          margin-left: -10px;
        }
      }

      &White[data-placement="bottom"] {
        &::after {
          border-bottom: 10px solid #fff !important;
          top: -5px !important;
        }
      }

      &White[data-placement="right"] {
        &::after {
          border-bottom: 10px solid #fff !important;
          top: -5px !important;
        }
      }

      &White[data-placement="top"] {
        &::before {
          border-top: 10px solid #fff !important;
          bottom: 3px !important;
        }
      }
    }
  }
}
.TooltipSide{
  top: 0px !important
}
