.Popover{
  &-body{
    background: themeColor(background-block);
    box-shadow: $gs-box-shadow-large;
    border-radius: getRadius(medium);
    //padding: $pd-sm 0;
  }
  &-tip{
    color: themeColor(background);
    fill: themeColor(background);
  }
}
