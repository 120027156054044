.MassAction{
  border-radius: 5px;
  border: 1px solid #EAF0F6;
  padding: 15px;
  width: 100%;
  color:#516F90;
  display: flex;
  flex-direction: column;
  gap: 15px;
  &:not(:first-child){
    margin-top: 15px;
  }
  &Title{
    color: black;
    font-weight: 600;
    width: 0;
    flex: 1 1 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &Created{
    p{
      display: inline-block;
      span{
        margin-bottom: 2px;
      }

    }
  }
}
.Metrics{
  width: 100%;
  margin-top: 15px;
}
.Progress{
  display: flex;
  flex-direction: column;
  width: 100%;
  &Info{
    display: flex;
    gap: 5px;
    &Bar{
      margin-bottom: 5px;
    }
  }
  :global {
    .ant-skeleton {
      height: 8px;
      overflow: hidden;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .ant-skeleton-input {
      height: 8px !important;
    }
  }
}

.Download{
  margin-top: 15px;
}

.Details{
  color:#00A4BD;
  cursor: pointer;
  width: max-content;
  transition: color 0.25s ease;
  margin-top: -5px;
  &:hover{
    color: #425B76
  }
}
 .ExportError{
    color: #F2545B;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    p{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      line-clamp: 4;
      box-orient: vertical;
    }
 }
