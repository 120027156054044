$xGap: 5px; // половина пространства между двумя открытыми нодами
$xSize: 120px; // это ширина четвертинки ноды
$ySize: 70px;
$iconSize: 45px;
$plusSize: 22px;
$topOffset: $iconSize/2 + 30px;
$leftOffset: $iconSize/2 + $xSize * 2 + 50px;

$branchColor: #cdd7e3;
$actionColor: transparent;
$triggerColor: transparent;
$ruleColor: transparent;
$utilColor: transparent;
$startColor: #00A4BD;
$endColor: #F2545B;

$mainTransition: all 0.3s ease-in-out;

:export {
  xGap: $xGap;
  xSize: $xSize;
  ySize: $ySize;
  iconSize: $iconSize;
  topOffset: $topOffset;
  leftOffset: $leftOffset;

  branchColor: $branchColor;
  actionColor: $actionColor;
  triggerColor: $triggerColor;
  ruleColor: $ruleColor;
  startColor: $startColor;
  endColor: $endColor;
}
