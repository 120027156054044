@import "default.module";

.Popover {
  z-index: 1001;
  &-body{
    box-shadow: none !important;
  }
  &::before, &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &-below{
    &::before, &::after {
      border: 9px solid transparent;
      border-bottom: 9px solid #7e8fa3;
      top: -10px;
    }
    &::after {
      top: -9px;
      border-bottom: 9px solid white;
    }
  }
  &-above{
    &::before, &::after {
      border: 9px solid transparent;
      border-top: 9px solid #7e8fa3;
      bottom: -10px;
    }
    &::after {
      bottom: -9px;
      border-top: 9px solid white;
    }
  }
}
.Popover-tip {
  opacity: 0;
}
.css-var-r0.ant-popover {
  --popover-z-index-popup: 1000 !important; // equal to modal z-index
}
