@import "main.module";

.Button{
  //min-width: 120px;
  padding: 7px;
  color: white;
  transition: $gs-transition;
  border: 1px solid transparent;
  border-radius: 5px;
  background: themeColor(action);
  position: relative;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;

  &:hover{
    color: themeColor(primary);
    //border-radius: 1px;
    background: lighten(themeColor(action), 3%);
    border-color: darken(themeColor(primary), 5%);
  }
  &:focus{
    color: themeColor(primary);
    background: lighten(themeColor(action), 3%);
    border-color: darken(themeColor(primary), 5%);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: themeColor(disabled);
  }
  &:hover:disabled{
    color: white;
    background: themeColor(disabled);
    border-color: transparent;
  }
  &.Mini {
    height: unset;
    padding: 1px 6px;
  }

  &.Micro {
    height: unset;
    padding: 0 2px;
  }

  &.Green {
    display: flex;
    align-items: center;
    background: rgba(0, 189, 165, 0.08);
    border: 1px solid #00BDA5;
    border-radius: 5px;
    color: #425B76;
    span {
      margin-right: 15px;
      white-space: nowrap;
    }
    &:hover,
    &:focus {
      background: rgba(0, 164, 189, 0.5);
      border: 1px solid #00A4BD;
    }
    &:disabled{
      background: rgba(0, 164, 189, 0.05);
      border: 1px solid rgba(0, 164, 189, 0.5);
      color: #99ACC2;
    }
  }
  &.White {
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #99ACC2;
    border-radius: 5px;
    color: #425B76;
    span {
      margin-right: 15px;
      white-space: nowrap;
    }
    &:hover,
    &:focus {
      background: rgba(222, 222, 222, 0.5);
      border: 1px solid #99ACC2;
    }
    &:disabled{
      background: rgba(211, 211, 211, 0.05);
      border: 1px solid rgba(205, 205, 205, 0.5);
      color: #99ACC2;
    }
  }

  &.Blue {
    display: flex;
    align-items: center;
    background: rgba(230, 246, 249, 1);
    border: 1px solid #00A4BD;
    border-radius: 5px;
    color: #425B76;
    span {
      margin-right: 15px;
      white-space: nowrap;
    }
    &:hover,
    &:focus {
      background: rgba(130, 233, 251, 0.749);
      border: 1px solid #00A4BD;
      border-color: #00A4BD;
    }
    &:disabled{
      background: rgba(0, 164, 189, 0.05);
      border: 1px solid rgba(0, 164, 189, 0.5);
      color: #99ACC2;
    }
  }

  &.Orange{
    display: flex;
    align-items: center;
    background: rgba(255, 143, 89, 0.08);
    border: 1px solid #FF8F59;
    border-radius: 5px;
    color: #425B76;
    span {
      margin-right: 15px;
      white-space: nowrap;
    }
    &:hover,
    &:focus {
      background: rgba(255, 143, 89, 0.2);
    }
  }

  &.Rounded {
    border-radius: 5px;
  }
  &.Transparent {
    background: transparent;
    border-color: transparent;
    &:hover,
    &:focus {
      color: inherit;
      background: transparent;
      border-color: transparent;
    }
    &:disabled,
    &:hover:disabled {
      opacity: 0.5;
      color: inherit;
      background: transparent;
      border-color: transparent;
    }
  }
  &.CancelType {
    background: rgba(153, 172, 194, 0.2);
    border: 1px solid #99ACC2;
    color: #425B76;
    &:hover{
      background: rgba(153, 172, 194, 0.5);
    }
    &:disabled{
      background: rgba(153, 172, 194, 0.1);
      border: 1px solid #99ACC2;
      color: #99ACC2;
      svg{
        path{
          fill:#99ACC2;
        }
        g{
          path{
            fill:#99ACC2;
          }
        }
      }
    }
  }
  &.BorderType {
    background: transparent;
    border: 1px solid #7C98B6;
    color: #425B76;
    &:hover{
      background: rgba(153, 172, 194, 0.5);
    }
    &:disabled{
      background: rgba(153, 172, 194, 0.1);
      border: 1px solid #99ACC2;
      color: #99ACC2;
    }
  }
  &.DarkType {
    background-color: palletteColor(gray);
    &:hover {
      color: white;
      background-color: lighten($color: palletteColor(gray), $amount: 5%);
    }
  }

  &.DeleteType {
    background: rgba(242, 84, 91, 0.2);
    border: 1px solid #F2545B;
    color: #425B76;
    &:hover {
      background: rgba(242, 84, 91, 0.4);
    }
    &:disabled{
      background: rgba(242, 84, 91, 0.1);
      border: 1px solid rgba(242, 84, 91, 0.5);
      color: #99ACC2;
    }
  }

  &.IndigoType {
    background-color: palletteColor(indigo);
    &:hover {
      color: white;
      background-color: lighten($color: palletteColor(indigo), $amount: 5%);
    }
  }

  &.Reverse{
    color: themeColor(primary);
    background-color: white;
    border-color: themeColor(primary);

    &:disabled {
      background-color: themeColor(disabled);
      color: white;
    }
    &:focus {
      background-color: themeColor(default);
      border-color: darken(themeColor(primary), 5%);
    }
  }
}


.MultiButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  padding: 7px 12px 9px;
  font-size: 14px;
  line-height: 16px;
  border: none;
  border-radius: getRadius(xxxlarge);
  box-sizing: border-box;
  background: themeColor(list-background);

  .ToggleIcon {
    width: 0;
    height: 0;
    margin-right: 6px;
    margin-top: 7px;
    border-style: solid;
    border-width: 6px 5.5px 0;
    border-color: palletteColor(eerie) transparent transparent transparent;
  }
}

.GroupedButton {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  height: 30px;
  line-height: 16px;
  font-weight: 600;
  //background: palletteColor(indigo);
  border-radius: 5px;
  overflow: hidden;
  color: white;
  border: 1px solid transparent;
  transition: all 400ms cubic-bezier(0, 0, 0.58, 1);
  cursor: pointer;
  &:not(.notOnly) {
    &:hover {
      border-color: black;
    }
  }
  .label {
    height: 100%;
    transition: all 400ms cubic-bezier(0, 0, 0.58, 1);
    border-radius: 0;
    border: none;
    &:hover {
      color: black;
    }
  }
  .ToggleIconWrap {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-left-color: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
  .ToggleIcon {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5.5px 0;
    border-color: currentColor transparent transparent transparent;
    transition: all 400ms cubic-bezier(0, 0, 0.58, 1);
  }
}
