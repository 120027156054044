@import "main.module";

.wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.content {
  width: 600px;
  flex: 1 1 600px;
  height: 100%;
  //padding: var(--padding-xl);
  padding: 44px;
  background-color: var(--color-bg-base);
  position: relative;
}

.staticRight {
  background: linear-gradient(270deg, #3748E6 0%, #4B64FF 100%), #E6E9EE;
  height: 100%;
  width: 840px;
  min-width: 0;
  overflow: hidden;
  flex: 10 10 840px;
  display: flex;
  align-items: center;
  padding: 44px;
}

