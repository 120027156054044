@import "lib/base/colors.module";
@import "lib/base/animation.module";

html,
body {
  box-sizing: border-box;
  background: themeColor(list-background);
  transition: $gs-transition-fast;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  color: palletteColor(flat-earth);
}

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}


a {
  color: var(--color-link);
  cursor: pointer;
  text-decoration: none;
  &:hover, &:active, &:active:hover, &:focus, &:visited {
    text-decoration: none;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}
input[type=number]{
-moz-appearance: textfield;
}
