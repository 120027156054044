.collapsedLogoWrapper {
  $size: 40px;

  width: $size;
  height: $size;
  position: relative;

  .collapsedLogo {
    width: $size;
    height: $size;
  }

  .collapseIcon {
    width: 18px;
  }

  .collapseButton {
    width: $size;
    height: $size;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    cursor: pointer;
    background: var(--color-bg-base);
    color: var(--color-text);
    font-size: 16px;
  }

  &:hover .collapseButton {
    opacity: 0.75;
  }
}
