@import "main.module";

.Categories {
  &Wrap {
    height: 100%;
    overflow: auto;
    min-width: 270px;
    max-width: 270px;
    padding: var(--padding);
    background-color: var(--color-bg-base);
    border-radius: 8px;
    p{
      color: #425B76;
    }
    h3 {
      font-weight: 600;
      padding: 10px 15px;
      font-size: 16px;
      border-bottom: 1px solid palletteColor(list-background);
    }
  }
  &Item {
    display: flex;
    justify-content: space-between;
    grid-gap: 0.5em;
    gap: 0.5em;
    padding: 8px 11px;
    height: 35px;
    border-radius: 5px;
    transition: all 0.2s linear;
    font-weight: 600;
    cursor: pointer;
    color: #425B76;
    margin-bottom: 5px;
    p {
      font-size: 14px;
      font-weight: inherit;
      flex-grow: 1;
      color: #425B76;
    }
    &.active {
      p {
        cursor: default;
        pointer-events: none;
        color: #425B76;
      }
      background: #CCEDF2;
      &:hover{
        background: #CCEDF2;
      }
    }
    .Actions {
      opacity: 0;
    }
    &:hover {
      background: #EAF0F6;
      .Actions {
        opacity: 1;
      }
    }
  }
  &AddNew {
    color: #00A4BD;
    padding: 0;
    &:hover{
      color: #253342;
    }
  }
}
.Header{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 20px;
  p{
    color: #425B76;
  }
  &Title{
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
  }

}
