@import "main.module";
.gslink{
  color: themeColor(main);
  text-decoration: none;
}
.violetlink{
  text-decoration: none;
  font-size: 16px;
  color: themeColor(main);
  cursor: pointer;
  &:hover{
    color: #23527c;
  }
}

