@import "main.module";

.Modal {
  display: flex;
  padding: 25px 30px;
  background-color: white;
  border-radius: 10px;
  width: 1140px;
  &Wrap {
    width: 100%;
    max-width: 1140px;
  }
  &Left {
    width: 215px;
    flex-shrink: 0;
  }
  &Right {
    flex-grow: 1;
    overflow: hidden;
  }
  &Flow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 400px;
    border: 1px solid palletteColor(gray);
    border-radius: 10px;
    overflow: hidden;
    &Controls {
      position: absolute;
      bottom: 0;
      left: 8px;
    }
  }
  &Nav {
    margin-right: 15px;
    h3 {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 13px;
    }
    &Category {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      h4 {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      a {
        // width: max-content;
        width: 100%;
        border-radius: 5px;
        transition: all 400ms cubic-bezier(0, 0, 0.58, 1);
        padding: 10px 3px 10px  10px;
        // margin: 5px 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        color: #425B76 !important;
        &:hover{
          background-color: #EAF0F6 !important;
        }
      }
    }
  }
  &TopRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 20px;
  }
  &CloseBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: auto;
    background-color: palletteColor(list-background);
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
      background-color: darken($color: palletteColor(list-background), $amount: 10);
    }
  }
  &ShareTemplateBtn {
    display: flex;
    align-items: center;
    color: #425B76;
    span {
      font-size: 14px;
      font-weight: 600;
      color: #5f7a98;
    }
    &:hover{
      color: #5f7a98;
      svg{
        path{
          fill: #5f7a98;
        }
      }
    }
  }
}


.AboutTemplate {
  display: flex;
  gap: 2em;
  padding-top: 30px;
  h3 {
    font-weight: 600;
    font-size: 14px;
  }
  p {
    padding-top: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
  &Left {
    flex-grow: 1;
  }
  &Right {
    width: 30%;
    flex-shrink: 0;
  }
}

.Author {
  display: flex;
  align-items: center;
  gap: 1em;
  padding-top: 15px;
  &Left {

  }
  &Right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.5;
    strong {
      font-weight: 600;
      margin-top: 5px;
    }
  }
}
.Layout{
  height: 100%;
  width: 100%;
  background-color: #D9D9D9;
}

.ControlBtns{
  top: 20px;
}
