.wrapper {
  position: relative;
  text-align: center;
  color: white;
  &.indigoBg {
    background: #6A78D1;
  }
}
.img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.badge {
  position: absolute;
  bottom: -8px;
  right: -14px;
}
.badgeRight {
  position: absolute;
  bottom: -3px;
  right: 18px;
}
.badgeSmall {
  position: absolute;
  bottom: -3px;
  right: -10px;
}
