// margin
$mg-none: 0px;

$mg-xxs: 2px;
$mg-xs: 4px;
$mg-sm: 8px;
$mg-md: 16px;
$mg: $mg-md;
$mg-lg: 24px;
$mg-xl: 32px;
$mg-xxl: 64px;

// padding
$pd-none: 0px;

$pd-xxs: 2px;
$pd-xs: 4px;
$pd-sm: 8px;
$pd-md: 16px;
$pd-lg: 24px;
$pd-xl: 32px;
$pd-xxl: 64px;
