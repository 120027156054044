// base shadow
$gs-box-shadow: 0px 0px 2px 0.5px themeColor(border-radius-color);
$gs-box-shadow-small: 0px 0px 2px 0.5px themeColor(border-radius-color);
$gs-box-shadow-large: 0px 0px 4px 2px themeColor(border-radius-color);
$gs-box-shadow-left-small: -2px 0px 2px 0.5px themeColor(border-radius-color);
$gs-box-shadow-right-small: 2px 0px 2px 0.5px themeColor(border-radius-color);
$gs-box-shadow-bottom-small: 0px 2px 4px 0px themeColor(border-radius-color);
$gs-box-shadow-left-large: -2px 0px 6px themeColor(border-radius-color);
$gs-box-shadow-right-large: 2px 0px 4px 2px themeColor(border-radius-color);

$gs-box-shadow-dark: 0px 0px 2px 2px rgba(129, 129, 129, 0.6);
$gs-box-shadow-dark-over-small: 0px 0px 2px 2px rgba(129, 129, 129, 0.2);
