@import "main.module";

.ActionPopup {
  min-width: 95px;
  border-radius: 5px;
  border: 1px solid #99ACC2;
  position: relative;

  button, a {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    text-align: left;
    color: palletteColor(heffalump);
    padding: 8px 10px;
    font-size: 14px;
    border: none;
    background-color: transparent;
    transition: all 0.25s ease;
    &:disabled{
      color:#CBD6E2;
      cursor: not-allowed;
      &:hover{
        color:#CBD6E2;
      }
    }
    &:hover {
      color: #00A4BD;
      svg{
        path{
          fill: #00A4BD;
        }
      }
    }
  }
}
