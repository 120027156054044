.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding);
  gap: 40px;
}
.title {
  font-size: 16px;
  font-weight: 600;
}
