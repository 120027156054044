.container {
  width: 210px;
  flex-shrink: 0;
  background-color: var(--color-bg-base);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
}

.link {
  display: block;
  padding: var(--padding-xs) var(--padding);
  transition: all 0.25s ease;
  color: var(--color-text);
  &:hover{
    background-color: var(--color-primary-bg);
    color: var(--color-text);
  }

  &:global(.--active) {
    border-left: 1px solid var(--color-primary);
  }
}
