@import "main.module";

.Modal {
  padding: 20px;
  max-width: 320px;
  width: 100vw;
  &Header {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    h2 {
      font-weight: 600;
      font-size: 16px;
    }
  }
  &CloseBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: auto;
    background-color: palletteColor(list-background);
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
      background-color: darken($color: palletteColor(list-background), $amount: 10);
    }
  }
  &Footer {
    padding-top: 20px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
}
.CustomRow{
  div:first-child{
    margin-bottom: 10px;
  }
}
